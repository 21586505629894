import wx from "weixin-js-sdk";
import axios from "axios";

const shareName = [
  { name: "home", title: "联想公益平台" },
  { name: "rank", title: "积分排名-联想公益平台" },
  { name: "team", title: "公益团队-联想公益平台" },
  { name: "calendar", title: "公益日历-联想公益平台" },
  { name: "my", title: "个人中心-联想公益平台" },
  { name: "information", title: "个人资料-联想公益平台" },
  { name: "myActivity", title: "我的公益-联想公益平台" },
  { name: "myComment", title: "我的评论-联想公益平台" },
  { name: "integralHistory", title: "积分历史-联想公益平台" },
];

export default {
  getShareInfo(activity) {
    let url = window.location.href;
    axios.post(`activity/share?url=${url}`).then((res) => {
      console.log(res);
      if (res.code === 200) {
        console.log(res.data);
        let appId = res.data.appid;
        let timestamp = res.data.timestamp;
        let nonceStr = res.data.nonceStr;
        let signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，
          appId: appId,
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: signature,
          jsApiList: [
            "updateAppMessageShareData",
            "updateTimelineShareData",
            "onMenuShareTimeline",
            "onMenuShareAppMessage",
          ],
        });
        wx.ready(function () {
          //分享到朋友圈
          wx.onMenuShareTimeline({
            title: activity.name, // 分享时的标题
            link: url, // 分享时的链接
            imgUrl: activity.image, // 分享时的图标
            success: function () {
              axios
                .post(`share`, {
                  type: "activity",
                  id: activity.id,
                })
                .then((res) => {
                  console.log(res, "分享成功");
                });
            },
            cancel: function () {
              console.log("取消分享");
            },
          });
          //分享给朋友
          wx.onMenuShareAppMessage({
            title: activity.name,
            desc: "活动地址：" + activity.address,
            link: url,
            imgUrl: activity.image,
            type: "",
            dataUrl: "",
            success: function () {
              axios
                .post(`share`, {
                  type: "activity",
                  id: activity.id,
                })
                .then((res) => {
                  console.log(res, "分享成功");
                });
            },
            cancel: function () {
              console.log("取消分享");
            },
          });
        });
      }
    });
  },
  noticeShare(notice) {
    let url = window.location.href;
    axios.post(`activity/share?url=${url}`).then((res) => {
      console.log(res);
      if (res.code === 200) {
        console.log(res.data);
        let appId = res.data.appid;
        let timestamp = res.data.timestamp;
        let nonceStr = res.data.nonceStr;
        let signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，
          appId: appId,
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: signature,
          jsApiList: [
            "updateAppMessageShareData",
            "updateTimelineShareData",
            "onMenuShareTimeline",
            "onMenuShareAppMessage",
          ],
        });
        wx.ready(function () {
          //分享到朋友圈
          wx.onMenuShareTimeline({
            title: notice.name, // 分享时的标题
            link: url, // 分享时的链接
            imgUrl: notice.image, // 分享时的图标
            success: function () {
              axios
                .post(`share`, {
                  type: "notice",
                  id: notice.id,
                })
                .then((res) => {
                  console.log(res, "分享成功");
                });
            },
            cancel: function () {
              console.log("取消分享");
            },
          });
          //分享给朋友
          wx.onMenuShareAppMessage({
            title: notice.name,
            desc: notice.title,
            link: url,
            imgUrl: notice.image,
            type: "",
            dataUrl: "",
            success: function () {
              axios
                .post(`share`, {
                  type: "notice",
                  id: notice.id,
                })
                .then((res) => {
                  console.log(res, "分享成功");
                });
            },
            cancel: function () {
              console.log("取消分享");
            },
          });
        });
      }
    });
  },
  commentShare(comment) {
    let url = window.location.href;
    axios.post(`activity/share?url=${url}`).then((res) => {
      console.log(res);
      if (res.code === 200) {
        console.log(res.data);
        let appId = res.data.appid;
        let timestamp = res.data.timestamp;
        let nonceStr = res.data.nonceStr;
        let signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，
          appId: appId,
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: signature,
          jsApiList: [
            "updateAppMessageShareData",
            "updateTimelineShareData",
            "onMenuShareTimeline",
            "onMenuShareAppMessage",
          ],
        });
        wx.ready(function () {
          //分享到朋友圈
          wx.onMenuShareTimeline({
            title: comment.name, // 分享时的标题
            link: url, // 分享时的链接
            imgUrl: comment.image, // 分享时的图标
            success: function () {},
            cancel: function () {
              console.log("取消分享");
            },
          });
          //分享给朋友
          wx.onMenuShareAppMessage({
            title: comment.name,
            desc: comment.title,
            link: url,
            imgUrl: comment.image,
            type: "",
            dataUrl: "",
            success: function () {},
            cancel: function () {
              console.log("取消分享");
            },
          });
        });
      }
    });
  },
  commShare(name) {
    let Text;
    let url = window.location.href;
    shareName.some((item) => {
      if (item.name === name) {
        Text = item.title;
        return true;
      }
    });
    axios.post(`activity/share?url=${url}`).then((res) => {
      console.log(res);
      if (res.code === 200) {
        console.log(res.data);
        let appId = res.data.appid;
        let timestamp = res.data.timestamp;
        let nonceStr = res.data.nonceStr;
        let signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，
          appId: appId,
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: signature,
          jsApiList: [
            "updateAppMessageShareData",
            "updateTimelineShareData",
            "onMenuShareTimeline",
            "onMenuShareAppMessage",
          ],
        });
        wx.ready(function () {
          //分享到朋友圈
          wx.onMenuShareTimeline({
            title: Text, // 分享时的标题
            link: url, // 分享时的链接
            imgUrl: "https://gy.joinusad.com/02.jpg", // 分享时的图标
            success: function () {
              if (name === "home" || "rank" || "team" || "calendar") {
                axios
                  .post(`share`, {
                    type: name,
                    id: "",
                  })
                  .then((res) => {
                    console.log(res, "分享成功");
                  });
              }
            },
            cancel: function () {
              console.log("取消分享");
            },
          });
          //分享给朋友
          wx.onMenuShareAppMessage({
            title: Text,
            desc: "益起联想 梦想未来",
            link: url,
            imgUrl: "https://gy.joinusad.com/02.jpg",
            type: "",
            dataUrl: "",
            success: function () {
              if (name === "home" || "rank" || "team" || "calendar") {
                axios
                  .post(`share`, {
                    type: name,
                    id: "",
                  })
                  .then((res) => {
                    console.log(res, "分享成功");
                  });
              }
            },
            cancel: function () {
              console.log("取消分享");
            },
          });
        });
      }
    });
  },
};
