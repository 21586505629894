let u = navigator.userAgent;
let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
let isiOS = u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
let isPc = u.includes("Electron") && u.includes("cochat");

const registerHandler = (name, callback) => {
  if (isiOS) {
    setupWebViewJavascriptBridge((bridge) => {
      bridge.registerHandler(name, callback);
    });
  } else if (isAndroid) {
    window[name] = callback;
  }
};
const callHandler = (name, parmas, callback) => {
  console.log(`------- 发送 Native -- ${name}`, parmas);
  if (isiOS) {
    setupWebViewJavascriptBridge((bridge) => {
      bridge.callHandler(name, parmas, callback);
    });
  } else if (isAndroid) {
    if (window.android && window.android[name]) {
      if (!Array.isArray(parmas) && typeof parmas === "object") {
        parmas = JSON.stringify(parmas);
      }
      if (callback) callback(window.android[name](parmas));
      else window.android[name](parmas);
    }
  } else if (isPc) {
    if (window.parent && window.parent.cochat && window.parent.cochat[name]) {
      window.parent.cochat[name](parmas, callback);
    }
  }
};

const setupWebViewJavascriptBridge = (callback) => {
  if (window.WebViewJavascriptBridge) {
    return callback(window.WebViewJavascriptBridge);
  }
  if (window.WVJBCallbacks) {
    return window.WVJBCallbacks.push(callback);
  }
  window.WVJBCallbacks = [callback];
  var WVJBIframe = document.createElement("iframe");
  WVJBIframe.style.display = "none";
  WVJBIframe.src = "https://__bridge_loaded__";
  document.documentElement.appendChild(WVJBIframe);
  setTimeout(() => {
    document.documentElement.removeChild(WVJBIframe);
  }, 0);
};

const GetToken = () => {
  return new Promise((resolve, reject) => {
    let callback = (val) => {
      val = typeof val === "string" ? JSON.parse(val) : val;
      let { code, data } = val;
      if (code === 200) {
        let token = data.token;
        resolve(token);
      }
    };
    if (isiOS || isAndroid) {
      registerHandler("getTokenByJwtTokenCallback", callback);
    }
    callHandler("getTokenByJwtToken", "");
    setTimeout(() => {
      reject("false");
    }, 1000);
  });
};

export { GetToken };
