<template>
  <div class="tabbar-box">
    <van-tabbar route v-model="active" active-color="#000000" @change="onChange">
      <van-tabbar-item to='/'>
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? home2 : home1" class="home" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to='/activityList'>
        <span>公益活动</span>
        <template #icon="props">
          <img :src="props.active ? activity2 : activity1" class="activity" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>公益积分</span>
        <template #icon="props">
          <img :src="props.active ? integral2 : integral1" class="integral" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to='/my'>
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? my2 : my1" class="my" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: "tabbar",
  data() {
    return {
      active: 0,
      home1: require("../assets/img/08.png"),
      home2: require("../assets/img/09.png"),
      activity1: require("../assets/img/10.png"),
      activity2: require("../assets/img/11.png"),
      integral1: require("../assets/img/12.png"),
      integral2: require("../assets/img/13.png"),
      my1: require("../assets/img/14.png"),
      my2: require("../assets/img/15.png"),
      value: "",
    };
  },
  methods: {
    comment() {},
    onChange(index) {
      console.log(index);
      if (index === 2) {
        this.$dialog
          .alert({
            message: "建设中，敬请期待",
          })
          .then(() => {
            // on close
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.tabbar-box {
  padding-top: 200px;
}
.home {
  width: 42px;
  height: 44px;
}
.activity {
  width: 46px;
  height: 40px;
  margin-bottom: 4px;
}
.integral {
  width: 46px;
  height: 40px;
  margin-bottom: 4px;
}
.my {
  width: 36px;
  height: 37px;
  margin-bottom: 7px;
}
/deep/.van-tabbar-item__text {
  font-family: SourceHanSansCN-Light, SourceHanSansCN;
}
</style>