<template>
  <div class="home">
    <van-notice-bar left-icon="volume-o" :scrollable="false" background="#FFE0BD" color="#A24200" v-if="showBar">
      <van-swipe vertical style="height: 25px;" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="item in noticeBar" :key="item.id" @click="tolink(item)">{{item.name}}</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
    <main>
      <header>
        <img src="~img/logo.png" class="logo">
        <div @click="$router.push({path:'/my'})">
          <img v-if="user.avatar" :src="user.avatar" class="header_img">
          <img v-else src="~img/16.png" class="header_img">
          <p class="one-txt-cut">{{user.name}}</p>
        </div>
      </header>

      <van-swipe class="header_swipe" :autoplay="3000" indicator-color="white" @change="onChange">
        <van-swipe-item v-for="(item, index) in list" :key="index">
          <div class="swipe_model" @click="tolink(item)">
            <img :src="item.file" alt="">
          </div>
        </van-swipe-item>
        <template #indicator>
          <ul class="indicators indicator">
            <li v-for="(item, index) in list" :key="index" :class="{active: current === index}"></li>
          </ul>
        </template>
      </van-swipe>
      <div class="num_box">
        <ul>
          <li>
            <router-link to="chart?id=1">
              <p class="p1">{{statistics.users}}</p>
              <p class="p2">公益成员</p>
            </router-link>
          </li>
          <li>
            <router-link to="team">
              <p class="p1">{{statistics.teams}}</p>
              <p class="p2">公益团队</p>
            </router-link>
          </li>
          <li>
            <router-link to="chart?id=2">
              <p class="p1">{{statistics.activity_times}}</p>
              <p class="p2">公益时长</p>
            </router-link>
          </li>
          <li>
            <router-link to="activityList">
              <p class="p1">{{statistics.activitires}}</p>
              <p class="p2">活动数量</p>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="nav_box">
        <ul>
          <li>
            <router-link to="activityList">
              <img src="~img/18.png" class="img1">
              <p>参加公益</p>
            </router-link>
          </li>
          <li>
            <router-link to="calendar">
              <img src="~img/19.png" class="img2">
              <p>公益日历</p>
            </router-link>
          </li>
          <li>
            <router-link to="notice">
              <img src="~img/20.png" class="img3">
              <p>公益报道</p>
            </router-link>
          </li>
          <li>
            <router-link to="myActivity">
              <img src="~img/21.png" class="img4">
              <p>我的公益</p>
            </router-link>
          </li>
        </ul>
      </div>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="list_box">
          <div class="tabs">
            <p v-for="item in tabs" :key="item.id" :class="{p_active:item.id === activeTab}" @click="changeTab(item.id)">
              {{item.name}}</p>
            <div class="tab_active" :style="`left:${leftNum}rem`"></div>
          </div>
          <ul class="list">
            <li v-for="item in activityList" :key="item.id" @click="toActivityInfo(item)">
              <div class="left">
                <img :src="item.image">
                <div class="top_status">
                  <img v-if="item.is_enroll === 1" src="~img/47.png" alt="">
                  <img v-if="item.status === 2" src="~img/48.png" alt="">
                  <p v-if="item.is_enroll === 1">报名中</p>
                  <p v-if="item.status === 2">已结束</p>
                  <img v-if="item.status === 4" src="~img/59.png" alt="">
                </div>
                <div class="bom_status" v-if="item.type === 2">
                  <p>外部活动</p>
                </div>
              </div>
              <div class="right">
                <p class="p1 one-txt-cut">{{item.name}}</p>
                <p class="p2 one-txt-cut" v-if="item.team">{{item.team.name}}</p>
                <div>
                  <img src="~img/22.png">
                  <p class="one-txt-cut">活动地点: {{item.address}}</p>
                </div>
                <div v-if="item.label.indexOf('enroll_time') !== -1">
                  <img src="~img/23.png">
                  <p class="one-txt-cut">报名时间: {{item.enroll_start_time}}-{{item.enroll_end_time}}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </main>
    <tabbar />
  </div>
</template>

<script>
import { GetToken } from "../utils/getToken.js";
import tabbar from "../components/Tabbar.vue";
import wxShare from "../utils/wxShare.js";
export default {
  components: {
    tabbar,
  },
  data() {
    return {
      current: 0,
      list: [
        {
          id: 1,
          type: "chinese",
          btn_img: "img/02.png",
          bg_img: require("../assets/img/01.jpeg"),
          date: "2023年3月24日",
          address: "成都世纪城洲际酒店",
        },
        {
          id: 2,
          type: "english",
          btn_img: "img/21.png",
          bg_img: require("../assets/img/01.jpeg"),
          date: "<p>24Mar<sup>th</sup> 2023 (Friday)</p>",
          address: "InterContinental Century City Chengdu",
        },
        {
          id: 3,
          type: "english",
          btn_img: "img/21.png",
          bg_img: require("../assets/img/01.jpeg"),
          date: "<p>24Mar<sup>th</sup> 2023 (Friday)</p>",
          address: "InterContinental Century City Chengdu",
        },
      ],
      tabs: [
        { id: 1, name: "联想公益活动" },
        { id: 2, name: "外部公益活动" },
      ],
      leftNum: 0,
      user: {},
      statistics: {},

      activeTab: 1,
      limit: 5,
      page: 0,
      status: 0,
      history: 0,
      name: "",
      date: "",
      tema_id: "",
      activityList: [],
      total: 0,
      loading: false,
      finished: false,
      noticeBar: [],
      showBar: false,
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    // console.log(this.user);
    this.getBanner();
    this.getStatistics();
    this.getNoticeBar();
  },
  mounted() {
    wxShare.commShare("home");
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    tolink(item) {
      console.log(item);
      if (item.url) {
        window.open(item.url, "_blank");
      }
    },
    async getBanner() {
      const res = await this.axios.get("banner");
      if (res.code === 200) {
        console.log(res, "轮播图");
        this.list = res.data;
      }
    },

    async getStatistics() {
      const res = await this.axios.get("statistics");
      if (res.code === 200) {
        console.log(res, "统计");
        this.statistics = res.data;
      }
    },
    /*
        type: 活动类型 1联想精彩活动 2外部公益活动 0 全部
        limit: 每页显示多少个
        page： 当前页数
        status： 活动状态 1 报名中 2 完成 3 取消 0全部
        history: 往期回顾 1 ，0
        name: 团队名称或活动标题
        date: 日历日期时传入
        tema_id: 团队id
    */
    async getActivity() {
      const res = await this.axios.get(
        `activity?type=${this.activeTab}&limit=${this.limit}&page=${this.page}&status=${this.status}&history=${this.history}&name=${this.name}&date=${this.date}&tema_id=${this.tema_id}`
      );
      if (res.code === 200) {
        console.log(res, "活动列表");
        const { list, meta } = res.data;
        this.total = meta.total;
        list.forEach((val) => {
          val.enroll_end_time = val.enroll_end_time.split(" ")[0];
          val.enroll_start_time = val.enroll_start_time.split(" ")[0];
          this.activityList.push(val);
        });
        this.loading = false;
        if (this.activityList.length >= this.total) {
          this.finished = true;
        }
      }
    },
    onLoad() {
      this.page++;
      this.getActivity();
    },

    changeTab(id) {
      console.log(id)
      this.activeTab = id;
      if (id === 1) {
        this.leftNum = 0;
      } else this.leftNum = 2.8;
      this.finished = false;
      this.activityList = [];
      this.page = 1;
      this.getActivity();
    },
    toActivityInfo(item) {
      this.$router.push({ path: `/activityDetails?id=${item.id}` });
    },
    async getNoticeBar() {
      const res = await this.axios.get("tip");
      console.log(res);
      if (res.code === 200) {
        this.noticeBar = res.data;
        if (this.noticeBar.length > 0) {
          this.showBar = true;
        } else {
          this.showBar = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  .van-notice-bar {
    margin-bottom: 15px;
  }
  main {
    width: 92%;
    margin: auto;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 30px;
      .logo {
        width: 289px;
        height: 58px;
      }
      div {
        display: flex;
        align-items: center;
        .header_img {
          width: 66px;
          height: 66px;
          border-radius: 50%;
          margin-right: 15px;
        }
        p {
          font-size: 32px;
          color: #5b5b5b;
          max-width: 165px;
        }
      }
    }
    .header_swipe {
      border-radius: 15px;
      .swipe_model {
        position: relative;
        .info {
          position: absolute;
          left: 5.07%;
          bottom: 10%;
          .btn_img {
            width: 145px;
          }
          .chinese {
            display: flex;
            align-items: center;
            margin-bottom: 11px;
            li {
              display: flex;
              align-items: center;
              margin-right: 2px;
              img {
                width: 13px;
                height: 14px;
              }
              p {
                font-size: 11px;
                font-family: FZLanTingHeiS;
                font-weight: 400;
                color: #181818;
              }
            }
          }
          .english {
            li {
              display: flex;
              align-items: center;
              img {
                width: 13px;
                height: 14px;
              }
              p {
                font-size: 11px;
                font-family: FZLanTingHeiS;
                font-weight: 400;
                color: #181818;
              }
              div {
                font-size: 11px;
                font-family: FZLanTingHeiS;
                font-weight: 400;
                color: #181818;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
      .indicators {
        position: absolute;
        bottom: 23px;
        right: 4.8%;
        display: flex;
        li {
          width: 39px;
          height: 6px;
        }
        // transform: translateX(-50%);
      }
      .indicator li {
        display: inline;
        background-color: #fff;
        border-radius: 6px;
        opacity: 0.3;
        transition: opacity 0.5s,
          //@animation-duration-fast
          background-color 0.5s; //@animation-duration-fast
        &:not(:last-child) {
          margin-right: 6px;
        }

        &.active {
          width: 60px;
          background-color: #fff;
          opacity: 1;
        }
      }
    }
    .num_box {
      width: 100%;
      height: 156px;
      border-radius: 18px;
      background: #fff;
      margin-top: 30px;
      ul {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        li {
          width: 147px;
          height: 115px;
          border-radius: 10px;
          background: url("../assets/img/17.png") no-repeat;
          background-size: contain;
          text-align: center;
          padding-top: 20px;
          .p1 {
            font-size: 32px;
            font-family: GothamMedium;
            color: #bb262e;
          }
          .p2 {
            font-size: 24px;
            font-family: GothamMedium;
            color: #bb262e;
            color: #8b7171;
          }
        }
      }
    }
    .nav_box {
      width: 100%;
      height: 156px;
      background: #ffffff;
      border-radius: 18px;
      margin-top: 30px;
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        li {
          img {
            margin: auto;
          }
          .img1 {
            width: 79px;
            height: 74px;
          }
          .img2 {
            width: 76px;
            height: 75px;
          }
          .img3 {
            width: 67px;
            height: 75px;
          }
          .img4 {
            width: 71px;
            height: 75px;
          }
          p {
            font-size: 25px;
            color: #7d7d7d;
          }
        }
      }
    }
    .list_box {
      margin-top: 30px;
      padding-top: 22px;
      padding-left: 30px;
      padding-right: 30px;
      background: #ffffff;
      border-radius: 18px;
      padding-bottom: 30px;
      overflow: hidden;
      .tabs {
        display: flex;
        // background: hotpink;
        position: relative;
        p {
          font-size: 27px;
          color: #000000;
          margin-right: 45px;
        }
        .p_active {
          font-size: 30px;
          font-weight: 500;
        }
        .tab_active {
          width: 116px;
          height: 6px;
          background: #bb262e;
          position: absolute;
          bottom: -10px;
          transition: 0.2s all;
        }
      }
      .list {
        padding-top: 24px;
        li {
          height: 263px;
          border-bottom: 1px solid #e4e4e4;
          display: flex;
          align-items: center;
          .left {
            display: flex;
            align-items: center;
            position: relative;
            img {
              width: 196px;
              height: 196px;
              border-radius: 10px;
            }
            .top_status {
              position: absolute;
              width: 96px;
              height: 32px;
              top: 0;
              left: 0;
              img {
                width: 100%;
                height: 32px;
                border-radius: 0;
                position: absolute;
                top: 0;
              }
              p {
                font-size: 24px;
                color: #fff;
                text-align: center;
                line-height: 32px;
                position: relative;
              }
            }
            .bom_status {
              width: 117px;
              height: 32px;
              background: linear-gradient(226deg, #ffb9a2 0%, #f26a52 100%);
              border-radius: 16px 0px 6px 0px;
              font-size: 24px;
              color: #fff;
              text-align: center;
              line-height: 32px;
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
          .right {
            font-family: FZLTHJW--GB1-0, FZLTHJW--GB1;
            padding-left: 20px;
            .p1 {
              font-size: 28px;
              width: 417px;
            }
            .p2 {
              font-size: 24px;
              color: #6f6f6f;
              margin-bottom: 45px;
              margin-top: 5px;
            }
            div {
              display: flex;
              align-items: center;
              margin-top: 10px;
              img {
                width: 21px;
                margin-right: 10px;
              }
              p {
                font-size: 24px;
                color: #6f6f6f;
                width: 380px;
                // margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>