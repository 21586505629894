<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { GetToken } from "./utils/getToken.js";

export default {
  data() {
    return {
      lenovoToken: "",
      iswx: false,
      token: "",
      ispc: false,
    };
  },
  created() {
    console.log("APP");
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      localStorage.setItem("ispc", 0);
      this.ispc = false;
    } else {
      localStorage.setItem("ispc", 1);
      this.ispc = true;
    }
    if (localStorage.getItem("token")) return;
    this.iswx = this.common.is_weixin(); // 是否微信内打开
    if (this.iswx) {
      localStorage.setItem("browser", false);
      let code = this.getQueryValue("code");
      if (code) {
        this.getWeixin(code);
      } else {
        this.common.getCode();
      }
    } else {
      this.getLenovoToken();
    }
  },
  mounted() {},
  methods: {
    async getLenovoToken() {
      try {
        const res = await GetToken();
        this.lenovoToken = res;
        console.log(this.lenovoToken, "---token");
        localStorage.setItem("browser", false);
        this.getLenovoUser(this.lenovoToken);
      } catch (e) {
        console.log(e, "---e");
        localStorage.setItem("browser", true);
        if (this.ispc) {
          this.$router.push({ path: "/ispc" });
        } else {
          this.$router.push({ path: "/login" });
        }
      }
    },
    async getLenovoUser(token) {
      const res = await this.axios.post("lenovo/user", {
        token,
      });
      console.log(res);
      if (res.code === 200) {
        if (res.data.is_register === 0) {
          localStorage.setItem("lenovouser", JSON.stringify(res.data));
          this.$router.push({ path: "/register" });
        } else {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          this.$router.push({ path: "/" });
        }
      }
    },
    async getWeixin(code) {
      const res = await this.axios.post("wechat/user", {
        code,
      });
      if (res.code === 200) {
        if (res.data.is_register === 0) {
          localStorage.setItem("openid", res.data.raw.openid);
          localStorage.setItem("wxinfo", JSON.stringify(res.data.raw));
          this.$router.push({ path: "/register" });
        } else {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          this.$router.push({ path: "/" });
        }
      } else {
        this.$toast(res.message);
      }
    },
  },
};
</script>



<style>
#app {
  background: #f8f8f8;
}
</style>
