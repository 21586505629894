import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
} // 重复点击路由报错的问题

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ispc',
    name: 'ispc',
    component: () => import('../views/ispc.vue'),
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "study" */ '../views/login.vue'),
  },
  {
    path: '/register', // 注册
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register/register.vue'),
  },
  {
    path: '/application', // 报名
    name: 'application',
    component: () => import(/* webpackChunkName: "application" */ '../views/application/application.vue'),
  },
  {
    path: '/activityList', // 活动列表
    name: 'activityList',
    component: () => import(/* webpackChunkName: "activity" */ '../views/activity/activityList.vue'),
  },
  {
    path: '/activityDetails', // 活动详情
    name: 'activityDetails',
    component: () => import(/* webpackChunkName: "activity" */ '../views/activity/ActivityDetails.vue'),
  },
  {
    path: '/commentDetails', // 评论详情
    name: 'commentDetails',
    component: () => import(/* webpackChunkName: "activity" */ '../views/activity/commentDetails.vue'),
  },
  {
    path: '/calendar', // 活动日历
    name: 'calendar',
    component: () => import('../views/calendar.vue'),
  },
  {
    path: '/notice', // 公益报道
    name: 'notice',
    component: () => import('../views/notice/notice.vue'),
  },
  {
    path: '/noticeDetails', // 公益报道
    name: 'noticeDetails',
    component: () => import('../views/notice/noticeDetails'),
  },
  {
    path: '/my', // 我的
    name: 'my',
    component: () => import('../views/my/my.vue'),
  },
  {
    path: '/information', // 个人资料
    name: 'information',
    component: () => import('../views/my/information.vue'),
  },
  {
    path: '/myActivity', // 我的公益
    name: 'myActivity',
    component: () => import('../views/my/myActivity.vue'),
  },
  {
    path: '/myComment', // 我的评论
    name: 'myComment',
    component: () => import('../views/my/myComment.vue'),
  },
  // integralHistory
  {
    path: '/integralHistory', // 积分历史
    name: 'integralHistory',
    component: () => import('../views/my/integralHistory.vue'),
  },
  {
    path: '/chart', // 排名
    name: 'chart',
    component: () => import('../views/chart.vue'),
  },
  {
    path: '/team', // 公益团队
    name: 'team',
    component: () => import('../views/team.vue'),
  },
  // {
  //   path: "/verificationlogin", // 核验登录
  //   name: "verificationlogin",
  //   component: () => import("../views/verification/verificationLogin.vue"),
  // },
  // {
  //   path: "/scancode", // 核验
  //   name: "scancode",
  //   component: () => import("../views/verification/ScanCode.vue"),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 }
  },
})
router.beforeEach((to, from, next) => {
  document.title = '联想公益平台'
  const token = localStorage.getItem('token')
  if (token || to.path === '/login' || to.path === '/register' || to.path === '/ispc') {
    next()
  }
})

export default router
